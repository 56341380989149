@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* #region  /**=========== Primary Color =========== */
    /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
    --tw-clr-primary-50: 240, 249, 255;
    --tw-clr-primary-100: 224, 242, 254;
    --tw-clr-primary-200: 186, 230, 253;
    --tw-clr-primary-300: 125, 211, 252;
    --tw-clr-primary-400: 56, 189, 248;
    --tw-clr-primary-500: 14, 165, 233;
    --tw-clr-primary-600: 2, 132, 199;
    --tw-clr-primary-700: 3, 105, 161;
    --tw-clr-primary-800: 7, 89, 133;
    --tw-clr-primary-900: 12, 74, 110;
    --clr-primary-50: rgb(var(--tw-clr-primary-50)); /* #f0f9ff */
    --clr-primary-100: rgb(var(--tw-clr-primary-100)); /* #e0f2fe */
    --clr-primary-200: rgb(var(--tw-clr-primary-200)); /* #bae6fd */
    --clr-primary-300: rgb(var(--tw-clr-primary-300)); /* #7dd3fc */
    --clr-primary-400: rgb(var(--tw-clr-primary-400)); /* #38bdf8 */
    --clr-primary-500: rgb(var(--tw-clr-primary-500)); /* #0ea5e9 */
    --clr-primary-600: rgb(var(--tw-clr-primary-600)); /* #0284c7 */
    --clr-primary-700: rgb(var(--tw-clr-primary-700)); /* #0369a1 */
    --clr-primary-800: rgb(var(--tw-clr-primary-800)); /* #075985 */
    --clr-primary-900: rgb(var(--tw-clr-primary-900)); /* #0c4a6e */
    /* #endregion  /**======== Primary Color =========== */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.hover-class:hover {
    background: #40404a;
}
@media (max-width: 768px) {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
/* Hide scrollbar for IE, Edge and Firefox */

@layer base {
    /* inter var - latin */
    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 100 900;
        font-display: optional;
        src: url('/fonts/inter-var-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
    }

    .cursor-newtab {
        cursor: url('/images/new-tab.png') 10 10, pointer;
    }

    /* #region  /**=========== Typography =========== */
    h1 {
        @apply font-primary text-2xl font-bold md:text-4xl;
    }

    h2 {
        @apply font-primary text-xl font-bold md:text-3xl;
    }

    h3 {
        @apply font-primary text-lg font-bold md:text-2xl;
    }

    h4 {
        @apply font-primary text-base font-bold md:text-lg;
    }

    body {
        @apply font-primary text-sm md:text-base;
    }
    /* #endregion  /**======== Typography =========== */

    .layout {
        /* 1100px */
        max-width: 68.75rem;
        @apply mx-auto w-11/12;
    }

    .bg-dark a.custom-link {
        @apply border-gray-200 hover:border-gray-200/0;
    }

    /* Class to adjust with sticky footer */
    .min-h-main {
        @apply min-h-[calc(100vh-56px)];
    }
}

input[type='file'] {
    display: none;
}
*:focus {
    outline: none;
}
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #0f0f13;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}
input {
    border: 1px solid transparent;
}

input[type='email'] {
    border: 1px solid transparent;
}
input[type='url'] {
    border: 1px solid #5b5b65;
    border-style: dashed;
}
input[type='number'] {
    border: 1px solid transparent;
}

input:focus {
    border: 1px solid #5b5b65;
    outline: none !important;
    box-shadow: 0 0 10px #5b5b65;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
input[type='number']:focus {
    border: 1px solid rgba(255, 255, 255, 0.4);
    outline: none !important;
    box-shadow: 0 0 10px #5b5b65;
}
input[type='url']:focus {
    border: 1px solid rgba(255, 255, 255, 0.4);
    outline: none !important;
    box-shadow: 0 0 10px #5b5b65;
}
input[type='email']:focus {
    border: 1px solid rgba(255, 255, 255, 0.4);
    outline: none !important;
    box-shadow: 0 0 10px #5b5b65;
}
textarea:focus {
    border: 1px solid transparent;
    box-shadow: none !important;
}
input[type='text']:focus {
    outline: none !important;
}
input[type='url']:focus {
    outline: none !important;
}
textarea {
    border: 1px solid transparent !important;
}
.box {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
button:focus {
    outline: none;
}
.border-dashed-custom {
    border: 1px dashed #40404a;
}

@layer utilities {
    .animated-underline {
        background-image: linear-gradient(#33333300, #33333300),
            linear-gradient(
                to right,
                var(--clr-primary-400),
                var(--clr-primary-500)
            );
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
    }
    @media (prefers-reduced-motion: no-preference) {
        .animated-underline {
            transition: 0.3s ease;
            transition-property: background-size, color, background-color,
                border-color;
        }
    }
    .animated-underline:hover,
    .animated-underline:focus-visible {
        background-size: 0 2px, 100% 2px;
    }
}
